
    import { mapActions, mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'LoginConfirmationModal',

        i18nOptions: { namespaces: ['game'] },

        extends: BaseModal,

        data () {
            return {
                modalName: 'login-confirmation',
            };
        },

        computed: {
            ...mapState(['game']),
        },

        mounted () {
            this.seenLoginConfirmation();
        },

        methods: {
            ...mapActions({
                seenLoginConfirmation: 'game/seenLoginConfirmation',
            }),
        },
    };
