import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/header.jpg'
import _imports_1 from '@public/img/logo.jpg'


const _hoisted_1 = {
  id: "header",
  role: "banner",
  "data-e2e": "global-header"
}
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "header-img"
}
const _hoisted_3 = {
  key: 1,
  class: "logo-img"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", {
      ref: "h1",
      tabindex: "-1"
    }, _toDisplayString(_ctx.$t('global:title')), 513),
    (_ctx.$store.state.ui.rapidTemplate)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.$store.state.ui.rapidTemplate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}