
    export default {
        props: {
            heavy: {
                default: false,
                required: false,
                type: Boolean,
            },
            label: {
                default: '',
                required: false,
                type: String,
            },
        },
        data () {
            return {
                modes: {
                    heavy: {
                        paths: [
                            'M17.6,2.4h67.1c8.3,0,15,6.7,15,15v67.4c0,8.3-6.7,15-15,15H17.6c-8.3,0-15-6.7-15-15V17.4C2.6,9.2,9.3,2.4,17.6,2.4z',
                            'M84.7,102.1H17.6c-9.5,0-17.2-7.7-17.2-17.2V17.4c0-9.5,7.7-17.2,17.2-17.2h67.1c9.5,0,17.2,7.7,17.2,17.2v67.4C102,94.3,94.2,102.1,84.7,102.1z M17.6,4.7c-7,0-12.7,5.7-12.7,12.8v67.4c0,7,5.7,12.8,12.7,12.8h67.1c7,0,12.8-5.7,12.8-12.8V17.4c0-7-5.7-12.8-12.8-12.8H17.6z',
                        ],
                        bounds: '0 0 102 102',
                    },
                    light: {
                        paths: [
                            'M17.5,1.9H168c8.3,0,15,6.7,15,15v119c0,8.3-6.7,15-15,15H17.5c-8.3,0-15-6.7-15-15v-119C2.5,8.7,9.2,1.9,17.5,1.9z',
                            'M168,152.8H17.5c-9.3,0-16.9-7.6-16.9-16.9v-119c0-9.3,7.6-16.9,16.9-16.9H168c9.3,0,16.9,7.6,16.9,16.9v119C184.9,145.2,177.3,152.8,168,152.8z M17.5,3.8c-7.2,0-13.1,5.9-13.1,13.1v119c0,7.2,5.9,13.1,13.1,13.1H168c7.2,0,13.1-5.9,13.1-13.1v-119c0-7.2-5.9-13.1-13.1-13.1H17.5z',
                        ],
                        bounds: '0 0 185 153',
                    },
                },
            };
        },
        computed: {
            currentMode () {
                return this.heavy ? 'heavy' : 'light';
            },
        },
    };
