
import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';
import { setRecaptchaV3Challenge } from '../../recaptcha';

const getDefaultState = () => reactive({
    awards: null,
    prizeWon: null,
    isPlayingForFun: null,
    isLimited: false,
    hasNew: false,
    shares: null,
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updateAwards (state, awards) {
        state.awards = awards;
    },
    updatePrizeWon (state, prizeWon) {
        state.prizeWon = prizeWon;
    },
    updatePlayingForFun (state) {
        state.isPlayingForFun = state.awards.isLimited;
    },
    updatedLimiter (state, data) {
        state.isLimited = data;
    },
    updateHasNew (state, data) {
        state.hasNew = data;
    },
    handleShareResults (state, results) {
        state.shares = results;
    },
    insertShare (state, shareName) {
        switch (shareName) {
            case 'ig_drpepper':
            case 'tt_drpepper':
            case 'fb_drpepper':
            case 'ig_panera':
            case 'tt_panera':
            case 'fb_panera':
                state.shares[shareName].isLimited = true;
                break;
            default:
                return  0;
        }
    },
};

const actions = {
    async getAwards ({ dispatch, commit }) {
        const result = await dispatch('makeCall', {
            type: 'get',
            endpoint: 'awards',
        });
        commit('updatedLimiter', result?.data?.awards?.isLimited);
        commit('updateHasNew', result?.data?.awards?.hasNew);
        return result;
    },

    async awardEvent ({ dispatch, commit }, { event }) {
        const data = {};

        commit('insertShare', encodeURIComponent(event));

        return dispatch('makeCall', {
            endpoint: `awards/events/${encodeURIComponent(event)}:award`,
            data,
        });
    },

    async getShares ({ dispatch, commit }) {
        const data = {};
        return dispatch('makeCall', {
            endpoint: 'awards/:bonus-status',
            data,
        });
    },

    async play ({ commit, dispatch, state }) {
        const data = {};

        commit('updatePlayingForFun');

        await setRecaptchaV3Challenge({ data, action: 'play' });

        await dispatch('makeCall', {
            endpoint: 'awards/:play',
            data,
        });

        return state.prizeWon;
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data,
    }) {
        let response;

        try {
            response = await axios[type](`${api.base}/${endpoint}`, data);
        }
        catch (err) {
            if (err.response?.status === 429) {
                // User was only limited, carry on
                ({ response } = err);
            }
            else {
                console.error(
                    `error making ${endpoint} call`,
                    err.message,
                    err,
                );

                throw err;
            }
        }

        if (response.data?.awards !== undefined) {
            commit('updateAwards', response.data.awards);
        }
        if (response.data?.prizeWon !== undefined) {
            commit('updatePrizeWon', response.data.prizeWon);
        }
        if (response.data?.result !== undefined) {
            commit('handleShareResults', response.data.result);
        }

        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
