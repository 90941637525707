import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@public/img/onboarding_anim.webp'


const _hoisted_1 = { class: "modal-overlay game-intro-overlay" }
const _hoisted_2 = {
  class: "modal",
  role: "dialog",
  "aria-labelledby": "h2"
}
const _hoisted_3 = { class: "new-user-copy" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "instruction-box" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        ($options.isMobile)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              innerHTML: _ctx.$t('game:instruction.mobile')
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        (!$options.isMobile)
          ? (_openBlock(), _createElementBlock("p", {
              key: 1,
              innerHTML: _ctx.$t('game:instruction.desktop')
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", {
            innerHTML: _ctx.$t('game:instruction.scoring', { interpolation: false })
          }, null, 8, _hoisted_7)
        ])
      ]),
      _createElementVNode("button", {
        "data-e2e": "game-intro-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _toDisplayString(_ctx.$t('game:pregame.cta')), 1)
    ])
  ]))
}