
    import { mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'GameIntroModal',

        i18nOptions: { namespaces: ['game'] },

        extends: BaseModal,

        data () {
            return {
                modalName: 'game-intro',
            };
        },

        computed: {
            ...mapState(['profile', 'game']),

            isMobile () {
                return window.matchMedia('(max-width: 767px)').matches;
            },
        },
    };
