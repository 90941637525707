
import I18NextVue from 'i18next-vue';
import { createApp, h } from 'vue';

import { i18next, i18nextDirective } from '../i18n';
import router from '../router';
import store from '../store';

export default function openModal (component, props) {
    return new Promise((resolve, reject) => {
        const modalApp = createApp({
            data () {
                return {
                    modalVNode: null,
                };
            },

            methods: {
                closeHandler (fn, arg) {
                    fn(arg);

                    const modal =
                        this.modalVNode?.component?.expose ||
                        this.modalVNode?.component?.proxy;
                    modal.destroyModal();
                },
            },

            render () {
                this.modalVNode = h(
                    component,
                    {
                        ...props,
                        onClose: () => {
                            modalApp.unmount();
                        },
                        onCancelModal: (arg) => this.closeHandler(reject, arg),
                        onCloseModal: (arg) => this.closeHandler(resolve, arg),
                    },
                );

                return this.modalVNode;
            },
        });

        // Modals are started up as a completely separate Vue app; we add some
        // properties here so they have the same functionality as the main app.
        modalApp.use(I18NextVue, { i18next });
        modalApp.directive('t', i18nextDirective);
        modalApp.use(router);
        modalApp.use(store);

        const containerDiv = document.createElement('div');
        return modalApp.mount(containerDiv);
    });
}

export { default as SessionExpirationModal } from './SessionExpirationModal.vue';
export { default as ErrorModal } from './ErrorModal.vue';
export { default as GameIntroModal } from './GameIntroModal.vue';
export { default as GameOverModal } from './GameOverModal.vue';
export { default as LoginConfirmationModal } from './LoginConfirmationModal.vue';
