import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog",
  "aria-labelledby": "h2"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "points-container" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_StatFrame = _resolveComponent("StatFrame")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay game-over-overlay",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        innerHTML: _ctx.$t('game:game_over.headline', { firstName: _ctx.profile.first_name })
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_StatFrame, {
          label: _ctx.$t('game:game_over.label')
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.score), 1)
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _createElementVNode("p", {
        innerHTML: _ctx.$t('game:game_over.body_copy', { interpolation: false })
      }, null, 8, _hoisted_4)
    ])
  ]))
}