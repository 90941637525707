
    import { mapState, mapActions } from 'vuex';
    import StatFrame from '@components/images/StatFrame.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'GameOverModal',

        i18nOptions: { namespaces: ['game'] },

        components: {
            StatFrame,
        },

        extends: BaseModal,

        props: {
            score: {
                required: true,
                type: Number,
                default: 0,
            },
        },

        data () {
            return {
                modalName: 'game_over',
            };
        },

        computed: {
            ...mapState('profile'),
        },

        mounted () {
            this.delayedClose();
        },

        methods: {
            ...mapActions({
                getScores: 'game/getScores',
            }),
            async delayedClose () {
                await this.getScores();
                await new Promise(resolve => setTimeout(resolve, 3000));
                this.closeModal();
            },
        },
    };
