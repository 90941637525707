import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "modal-overlay game-intro-overlay" }
const _hoisted_2 = {
  class: "modal",
  role: "dialog",
  "aria-labelledby": "h2"
}
const _hoisted_3 = {
  key: 0,
  class: "return-user-copy"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "new-user-copy"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.game.isReturnUser === 'yes')
        ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
            _createElementVNode("h2", {
              innerHTML: _ctx.$t('game:pregame.headline')
            }, null, 8, _hoisted_4),
            _createElementVNode("p", {
              innerHTML: _ctx.$t('game:pregame.body_copy')
            }, null, 8, _hoisted_5)
          ]))
        : (_ctx.game.isReturnUser === 'no')
          ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
              _createElementVNode("h2", {
                innerHTML: _ctx.$t('game:first_time.headline')
              }, null, 8, _hoisted_7),
              _createElementVNode("p", {
                innerHTML: _ctx.$t('game:first_time.body_copy')
              }, null, 8, _hoisted_8)
            ]))
          : _createCommentVNode("", true),
      _createElementVNode("button", {
        "data-e2e": "login-confirmation-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _toDisplayString(_ctx.$t('game:pregame.cta')), 1)
    ])
  ]))
}