import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-error lifecycle form-box" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BackgroundImage = _resolveComponent("BackgroundImage")
  const _component_HeadingUnderline = _resolveComponent("HeadingUnderline")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BackgroundImage),
    _createElementVNode("section", _hoisted_1, [
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_directive_t, 'headline_copy']
      ]),
      _createVNode(_component_HeadingUnderline),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_directive_t, 'body_copy']
      ])
    ])
  ], 64))
}