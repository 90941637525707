import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@public/img/mobile_cityline.png'
import _imports_1 from '@public/img/mobile_bushes.png'
import _imports_2 from '@public/img/mobile_header_logo.png'
import _imports_3 from '@public/img/mobile_form_bread.png'
import _imports_4 from '@public/img/desktop_cityline.png'
import _imports_5 from '@public/img/desktop_bushes.png'
import _imports_6 from '@public/img/desktop_header_logo.png'
import _imports_7 from '@public/img/desktop_form_bread.png'


export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"small-only\"><img src=\"" + _imports_0 + "\" class=\"background-cityline\"><img src=\"" + _imports_1 + "\" class=\"background-bushes\"><img src=\"" + _imports_2 + "\" class=\"background-header-logo\"><img src=\"" + _imports_3 + "\" class=\"form-breadtoss-logo\"></div><div class=\"large-only\"><img src=\"" + _imports_4 + "\" class=\"background-cityline\"><img src=\"" + _imports_5 + "\" class=\"background-bushes\"><img src=\"" + _imports_6 + "\" class=\"background-header-logo\"><img src=\"" + _imports_7 + "\" class=\"form-breadtoss-logo\"></div>", 2))
}