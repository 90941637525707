import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/large-heading_underline.png'
import _imports_1 from '@public/img/heading_underline.png'


const _hoisted_1 = { class: "underline-wrapper" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.ui.pageName == 'hub')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : (_openBlock(), _createElementBlock("img", _hoisted_3))
  ]))
}