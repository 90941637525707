import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "stat-frame" }
const _hoisted_2 = ["viewBox"]
const _hoisted_3 = ["d"]
const _hoisted_4 = ["d"]
const _hoisted_5 = { class: "stat-content" }
const _hoisted_6 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      x: "0px",
      y: "0px",
      viewBox: $data.modes[$options.currentMode].bounds,
      "xml:space": "preserve",
      class: _normalizeClass(`stat-svg-${$options.currentMode}`)
    }, [
      _createElementVNode("path", {
        fill: "currentColor",
        class: "stat-backer",
        d: $data.modes[$options.currentMode].paths[0]
      }, null, 8, _hoisted_3),
      _createElementVNode("path", {
        fill: "currentColor",
        class: "stat-border",
        d: $data.modes[$options.currentMode].paths[1]
      }, null, 8, _hoisted_4)
    ], 10, _hoisted_2)),
    _createElementVNode("div", _hoisted_5, [
      ($props.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_6, _toDisplayString($props.label), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}