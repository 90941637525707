import axios from 'axios';
import { set } from 'vue';

import api from '../../api';

const baseReturnVisitLocalStorageID = 'breadysettossreturnvisiter';

const returnVisitLocalStorageID = id => `${baseReturnVisitLocalStorageID}-${id}`;

const state = {
    highScore: 0,
    recentScore: 0,
    gamePlayed: false,
    scoresLoadedForSession: false,
    isReturnUser: 'unknown',
    hasSeenLoginConfirmation: false,
};

const mutations = {
    setScores (state, data) {
        for (const key in data) {
            state[key] = data[key];
        }
    },
    setGamePlayedFlag (state, data) {
        state.gamePlayed = data;
    },
    setScoresLoadedForSession (state) {
        state.scoresLoadedForSession = true;
    },
    updateReturnUser (state, data) {
        state.isReturnUser = data;
    },
    updateSeenLoginConfirmationFlag (state, data) {
        state.hasSeenLoginConfirmation = data;
    },
};

const actions = {
    async logScore ({ commit }, score) {
        try {
            await axios.post(`${api.base}/game/score`, { score });
            commit('setGamePlayedFlag', true);
        }
        catch (err) {
            console.error(err);
        }

    },
    async getScores ({ commit, rootState }) {
        try {
            const { data } = await axios.get(`${api.base}/game/${rootState.profile.id}/:scores`);
            commit('setScores', data);
            commit('setScoresLoadedForSession');
        }
        catch (err) {
            console.error(err);
        }
    },
    setReturnVisitFlag ({ rootState }) {
        const localStorageID = returnVisitLocalStorageID(rootState.profile.id);
        window.localStorage.setItem(localStorageID, 'yes');
    },
    async getReturnVisitFlag ({ rootState, commit }) {
        const localStorageID = returnVisitLocalStorageID(rootState.profile.id);
        const returnUser = window.localStorage.getItem(localStorageID);
        commit('updateReturnUser', returnUser ? returnUser : 'no');
    },
    seenLoginConfirmation ({ commit }) {
        commit('updateSeenLoginConfirmationFlag', true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
