
    import HeadingUnderline from '@components/HeadingUnderline.vue';
    import BasePage from './BasePage';
    import BackgroundImage from './BackgroundImage.vue';

    export default {
        name: 'ErrorPage',

        components: {
            BackgroundImage,
            HeadingUnderline,
        },

        extends: BasePage,

        data () {
            return {
                pageName: 'error',
            };
        },
        i18nOptions: { namespaces: ['error'] },
    };
