
    import { mapState } from 'vuex';

    export default {
        name: 'HeadingUnderline',

        computed: {
            ...mapState(['ui']),
        },
    };
