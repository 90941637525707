import axios from 'axios';

import api from '../../api';

const state = {
    pollResults: [],
    pollTakenThisSession: false,
};

const mutations = {
    setResults (state, data) {
        state.pollResults = data;
    },
    updatePollTakenThisSession (state) {
        state.pollTakenThisSession = true;
    },
};

const actions = {
    async getPollResults ({ commit }) {
        let response;

        try {
            const { data } = await axios.get(`${api.base}/poll/results`);
            commit('setResults', data);

            response = data;
        }
        catch (err) {
            console.error(err);
        }

        return response;
    },
    setPollTakenThisSession ({ commit }) {
        commit('updatePollTakenThisSession');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
