import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/footer_logo.png'


const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer-links" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "last_nav_item" }
const _hoisted_5 = {
  key: 0,
  class: "last_nav_item"
}
const _hoisted_6 = { "data-e2e": "footer-logged-in" }
const _hoisted_7 = { class: "disclaimer" }
const _hoisted_8 = { class: "disclaimer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("img", {
      src: _imports_0,
      alt: "Panera x Dr Pepper",
      class: "footer-logo"
    }, null, -1)),
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: _ctx.app.tb2URL + 'public/fulfillment/rules.pdf',
            target: "_blank"
          }, _toDisplayString(_ctx.$t('links:rules_copy')), 9, _hoisted_3)
        ]),
        _withDirectives(_createElementVNode("li", null, null, 512), [
          [_directive_t, 'links:privacy']
        ]),
        _createElementVNode("li", _hoisted_4, [
          _createElementVNode("a", {
            href: "/faq",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t('links:faq_copy')), 1)
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("li", { class: "break last_nav_item" }, null, -1)),
        (_ctx.loggedIn)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, [
                _createTextVNode(" Logged in as " + _toDisplayString(_ctx.profile.first_name) + " (Not you? ", 1),
                _createElementVNode("a", {
                  href: "/",
                  class: "btn_logout",
                  "data-e2e": "footer-logout",
                  onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.logOut && _ctx.logOut(...args)))
                }, " Log Out "),
                _cache[2] || (_cache[2] = _createTextVNode(") "))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_7, null, 512), [
      [_directive_t, { key: 'footer:disclaimer', tb2URL: _ctx.app.tb2URL }]
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_8, null, 512), [
      [_directive_t, { key: 'footer:disclaimer_2' }]
    ])
  ]))
}